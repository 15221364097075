
<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ formTitle }}</h4>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-overlay :show="unitLoad">
            <b-row>
                <b-col md="12" lg="12" sm="12">
                    <b-card no-body>
                        <template v-slot:headerTitle>
                            <h4 class="card-title">{{ $t('step_form.step_form_list') }}</h4>
                        </template>
                        <b-tabs class="mb-4" v-model="tabIndex">
                            <b-tab v-for="(item, index) in tabData" :key="index" :active="index === 0" @click="tabChange(index)">
                                <template v-slot:title>
                                    <strong> {{getName(item.tab_name)}}</strong>
                                </template>
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form  @submit.prevent="handleSubmit(saveFormData)" @reset.prevent="reset" >
                                        <b-row>
                                            <b-col lg="12" md="12" sm="12">
                                                <grid-layout :layout.sync="layout[index]"
                                                    :col-num="colNum"
                                                    :row-height="12"
                                                    :is-draggable="draggable"
                                                    :is-resizable="resizable"
                                                    :vertical-compact="true"
                                                    :use-css-transforms="true"
                                                >
                                                    <grid-item v-for="(item, dindex) in layout[index]"
                                                        :x="item.x"
                                                        :y="item.y"
                                                        :w="item.w"
                                                        :h="item.h"
                                                        :i="item.i"
                                                        :key="dindex"
                                                    >
                                                        <div v-if="item.i === 'InputField'">
                                                            <Input :data="formInputData[index][dindex]"/>
                                                        </div>
                                                        <div v-if="item.i === 'Dropdown'">
                                                            <Dropdown :data="formInputData[index][dindex]" :drpChangeData="formInputData[index][dindex + 1]" :nextIndex="dindex + 1" @updateDropdownData="updateDropdown" :key="componentKey"/>
                                                        </div>
                                                    </grid-item>
                                                </grid-layout>
                                            </b-col>
                                        </b-row>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button v-if="index !== 0" @click="tabIndex--" variant="primary" class="mr-2">{{ $t('component_settings.prev_tab') }}</b-button>
                                                <b-button v-if="index < totalTab" @click="tabIndex++" variant="primary" class="mr-2">{{ $t('component_settings.next_tab') }}</b-button>
                                                <b-button v-if="index === totalTab" type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                <router-link to="step-form" :class="'btn btn-danger mr-1'">{{ $t('globalTrans.cancel') }}</router-link>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </b-col>
            </b-row>
        </b-overlay>
    </b-container>
</template>
<script>
import Input from '../components/input-field'
import Dropdown from '../components/dropdown-field'
import { GridLayout, GridItem } from 'vue-grid-layout'
import { ValidationObserver } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { stepFormView } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { snakeToWords } from '@/Utils/fliter'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    Input,
    Dropdown,
    GridLayout,
    GridItem,
    ValidationObserver
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.save'),
      layout: [],
      draggable: false,
      resizable: false,
      colNum: 4,
      index: 0,
      formInputData: [],
      formTitle: '',
      tabData: '',
      service_id: '',
      step_id: '',
      service_name: '',
      step_name: '',
      tab_no: 0,
      tabIndex: 0,
      unitLoad: false,
      componentKey: 0,
      totalTab: 0
    }
  },
  mounted () {
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    if (this.$route.query.service_id && this.$route.query.step_id) {
        this.service_id = this.$route.query.service_id
        this.step_id = this.$route.query.step_id
        const serviceList = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === parseInt(this.$route.query.service_id))
        const stepList = this.$store.state.licenseRegistration.commonObj.stepNamesList.find(item => item.value === parseInt(this.$route.query.step_id))
        this.service_name = this.$i18n.locale === 'en' ? serviceList !== undefined ? serviceList.text_en : '' : serviceList !== undefined ? serviceList.text_bn : ''
        this.step_name = this.$i18n.locale === 'en' ? stepList !== undefined ? stepList.text_en : '' : stepList !== undefined ? stepList.text_bn : ''
        this.formTitle = this.service_name + ' - ' + this.step_name + ' - ' + this.$t('step_form.step_form')
    }
    this.loadData()
  },
  computed: {
  },
  methods: {
      loadData () {
        this.unitLoad = true
        const formData = {
            service_id: this.service_id,
            step_id: this.step_id
        }
        const params = Object.assign({}, formData, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        RestApi.getData(licenseRegistrationServiceBaseUrl, stepFormView, params).then(response => {
        if (response.success) {
          this.tabData = response.data
          const resultData = response.data
          resultData.forEach((item) => {
          const laArray = []
          const daArray = []
            item.layouts.forEach((item) => {
              const layJson = JSON.parse(item.layout)
              const dataJson = JSON.parse(item.data)
              laArray.push(layJson)
              daArray.push(dataJson)
            })
            this.layout.push(laArray)
            this.formInputData.push(daArray)
          })
          this.totalTab = this.formInputData.length - 1
          this.unitLoad = false
        }
      })
    },
    getName (snakeName) {
        return snakeToWords(snakeName)
    },
    updateDropdown: function (updata, nindex) {
      this.componentKey += 1
    },
    tabChange (val) {
        this.tab_no = val + 1
    },
    async saveFormData () {
    //   const tData = {}
    //   const sData = {}
      const data = this.formInputData
      data.forEach((item) => {
        // tData[item.field_name] = item.value
      })
    //   sData.service_id = this.service_id
    //   sData.step_id = this.step_id
    //   sData.step_name = this.step_name
    //   sData.tab_no = this.tab_no
    //   const formData = {
    //     data: tData,
    //     service_info: sData
    //   }
    //   let result = null
    //   result = await RestApi.postData(licenseRegistrationServiceBaseUrl, formDataStore, formData)
    //   if (result.success) {
    //     this.$toast.success({
    //       title: 'Success',
    //       message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
    //       color: '#D6E09B'
    //     })
    //   } else {
    //     this.$refs.form.setErrors(result.errors)
    //   }
    }
  }
}
</script>
<style scoped>
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-draggable-handle {
    position: absolute;
    width: 5px;
    height: 5px;
    top: 0;
    left: 0;
    bottom: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 2px 2px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}

.section {
  overflow: hidden;
  position: fixed;
  z-index: 999;
}
.fields:hover {
  background:rgb(217, 218, 216);
}
</style>
